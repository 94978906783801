import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {ActivatedRouteSnapshot, Router} from "@angular/router";
import {AuthService} from "../services/auth/auth.service";
import {catchError, tap} from "rxjs/operators";
import {environment} from "../../environments/environment";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class OptionsInterceptor implements HttpInterceptor {
	constructor(private router: Router) {}
	intercept(req: HttpRequest<any>, next: HttpHandler):
		Observable<HttpEvent<any>> {
		let token = localStorage.getItem('token');
		let laravelReq;
		if(req.url.indexOf(environment.apiUrl)!==-1 || req.url.indexOf('https://audio-api.cobrashowcreator.com')!==-1 && token){
			if(req.url.indexOf('https://audio-api.cobrashowcreator.com')!==-1){
				let token2 = localStorage.getItem('audioApiToken');
				if(token2){
					token = token2;
					localStorage.setItem('audioApiToken', '');
				}
			}
			laravelReq = req.clone(
				{
					headers: new HttpHeaders({
						Authorization: ["Bearer "+token],
					})
				}
			);
		}
		else{
			laravelReq = req.clone();
		}
		return next.handle(laravelReq).pipe(
			tap(
				error => this.handleResponse(req, error),
				error => this.handleError(req, error)
			)
		);
	}
	handleResponse(req: HttpRequest<any>, event: any) {

	}
	handleError(req: HttpRequest<any>, event: any) {
		if(event.status == 401){
			this.router.navigate(['/login']);
		}
	}
}
