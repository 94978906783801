import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ShowsService } from '../services/shows.service';
import { environment } from "../../environments/environment";
import { Show } from "../interfaces/show.interface";
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { fromEvent, pipe } from 'rxjs';
import { ExcludeEmailComponent } from '../user-report/exclude-email/exclude-email.component';
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-likes',
  templateUrl: './likes.component.html',
  styleUrl: './likes.component.scss'
})
export class LikesComponent implements OnInit {
  displayedColumns: string[] = ['show_code', 'custom_name', 'my_likes', 'total_user_likes', 'download_likes', 'download_user_likes', 'expand'];
  displayedColumns2: string[] = ['timestamp', 'show_code', 'my_likes', 'total_user_likes'];
  dataSource = new MatTableDataSource();
  loading = false;
  processing = false;
  expandedElement: any | null = null;
  total_reports = 0;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('matSort1')sort1!: MatSort;
  @ViewChild('matSort2')sort2!: MatSort;
  @ViewChild('search') search!: ElementRef;
  show: any;
  show_id: any;

  constructor(public showsService: ShowsService, public dialog: MatDialog) {
  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = "Shows per page:";
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort1;
    fromEvent(this.search.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
        })
      )
  }

  ngOnInit(): void {
    this.getReport();
  }

  getReport() {
    this.loading = true;
    this.showsService.myLikes().subscribe((response: any) => {
      this.dataSource = new MatTableDataSource(response.map((data: any) => ({
        ...data,
        childData: new MatTableDataSource()
      })));
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort1;
      this.total_reports = this.dataSource.filteredData.length;
      this.loading = false;
    });
  }

  toggleRow(element: any) {
    this.expandedElement = this.expandedElement === element ? null : element;
    if (this.expandedElement) {
      element.childData = new MatTableDataSource();
      this.loadChildren(element);
    }
  }

  loadChildren(element: any, download = false, userLikes = false){
    this.showsService.myLikesDetails(element.show_code).subscribe((response: any) => {
      element.childData = new MatTableDataSource(response);
      element.childData.sort = this.sort2;
      if(download){
        this.downloadCSV(element, userLikes)
      }
      // element.childData.map((data: any) => {
      //   data.show_date = new Date(data.show_date.split(' ')[0]);
      // });
    });
  }

  downloadLikes(element: any) {
    if(element.childData.data.length == 0){
      this.loadChildren(element, true);
    }
    else{
      this.downloadCSV(element, false)
    }
  }
  downloadUserLikes(element: any) {
    if(element.childData.data.length == 0){
      this.loadChildren(element, true, true);
    }
    else{
      this.downloadCSV(element, true)
    }
  }
  downloadCSV(element: any, userLikes: boolean){
    const recordsToDownload = element.childData.data.map((data: any) => {
      return [
        this.convertTime(data.timestamp),
        element.custom_name,
        userLikes ? data.total_user_likes : data.my_likes
      ]
    });

    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: (userLikes ? 'My Likes: ' : 'Total User Likes') + element.show_code,
      useTextFile: false,
      useBom: true,
      headers: ["Event Time", "Name", userLikes ? "Total User Likes" : "My Likes"]
    };

    new AngularCsv(recordsToDownload, (userLikes ? 'Total-User-Likes-' : 'My-Likes-') + element.show_code, csvOptions);
  }

  convertTime(seconds: number){
    let s = seconds % 60
    let minutes = (seconds - s) / 60
    let m = minutes % 60
    let h = (minutes - m) / 60
    return (h > 0 ? h + "h " : "") + m + "m " + s + "s"
  }
}
