<div class="pricing-content">
    <h2 class="popup-heading" mat-dialog-title>Show Listeners</h2>
    <mat-dialog-content class="mat-typography">
        <h1 [ngStyle]="{'color': 'var(--primary-color)'}">Activated</h1>
        <div class="pricing-details">
            <table mat-table [dataSource]="listenersData" class="pricing-table">
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef width="50%"> Date </th>
                    <td mat-cell *matCellDef="let listeners"> {{listeners.date}} </td>
                </ng-container>
                <ng-container matColumnDef="listeners">
                    <th mat-header-cell *matHeaderCellDef width="50%"> # Listeners </th>
                    <td mat-cell *matCellDef="let listeners"> {{listeners.listeners}} </td>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef width="50%"> Amount </th>
                    <td mat-cell *matCellDef="let listeners"> {{listeners.amount}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>      
            </table>
        </div>
    </mat-dialog-content>
</div>