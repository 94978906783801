<div class="content">
	<div class="my-show-table">
        <div class="finger-head">
                <mat-form-field>
                    <mat-label>From Date</mat-label>
                    <input matInput [matDatepicker]="picker1" [(ngModel)]="start_date">
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                  
                  <mat-form-field>
                    <mat-label>To Date</mat-label>
                    <input matInput [matDatepicker]="picker2" [(ngModel)]="end_date">
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 color="primary"></mat-datepicker>
                  </mat-form-field>
                                    
                <button mat-raised-button [ngStyle]="{'background-color': 'var(--accent-color)', 'color': 'var(--light-color)'}" class="add-btn" (click)="getReport()"><span style="line-height: 24px;margin-right: 5px;display: inline-block;">Submit</span></button>
                <button style="float: right" mat-raised-button [ngStyle]="{'background-color': 'var(--accent-color)', 'color': 'var(--light-color)'}" class="add-btn" (click)="downloadCsv()"><span style="line-height: 24px;margin-right: 5px;display: inline-block;">Download CSV</span></button>
        </div>
		<div class="mat-elevation-z8 ex">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="show_code">
                  <th mat-header-cell *matHeaderCellDef> Show code </th>
                  <td mat-cell *matCellDef="let element"> {{element.show_code}} </td>
                </ng-container>
              
                <!-- Name Column -->
                <!-- <ng-container matColumnDef="user_email">
                  <th mat-header-cell *matHeaderCellDef> User Email </th>
                  <td mat-cell *matCellDef="let element"> {{element.user_email}} </td>
                </ng-container> -->
                
                <!-- Name Column -->
                <ng-container matColumnDef="total_calls">
                  <th mat-header-cell *matHeaderCellDef> Total Calls </th>
                  <td mat-cell *matCellDef="let element"> {{element.total_calls}} </td>
                </ng-container>

                <!-- Name Column -->
                <!-- <ng-container matColumnDef="last_call_time">
                  <th mat-header-cell *matHeaderCellDef> Last Call Time </th>
                  <td mat-cell *matCellDef="let element"> {{element.last_call_time}} </td>
                </ng-container> -->
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td colspan="8" class="mat-cell">{{ loading ? 'Loading...':'No records found.'}}</td>
                </tr>
              </table>
		</div>
	</div>
</div>