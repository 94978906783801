<div class="pricing-content">
    <h2 class="popup-heading" mat-dialog-title>Activate Show</h2>
    <mat-dialog-content class="mat-typography">
        <p>By activating your show, you will remove the 30-second playback restriction. Any show listens within a 24-hour time window will be charged based on the following number of listeners price tier.</p>
        <p>For example, if you have 100 listeners in a 24-hour time window, you’ll be charged $249.</p>
        <div class="pricing-details">
            <button mat-button [ngStyle]="{'border-color': 'var(--primary-color) !important', 'color': 'var(--primary-color)'}" class="green-btn">Activate Show</button>
            <table mat-table [dataSource]="pricingData" class="pricing-table">
                <ng-container matColumnDef="range">
                    <th mat-header-cell *matHeaderCellDef width="50%"> # Listeners </th>
                    <td mat-cell *matCellDef="let pricing"> {{pricing.range}} </td>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef width="50%"> Amount </th>
                    <td mat-cell *matCellDef="let pricing"> {{pricing.amount}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>      
            </table>
        </div>
    </mat-dialog-content>
</div>