import { Component } from '@angular/core';

@Component({
  selector: 'app-listeners',
  templateUrl: './listeners.component.html',
  styleUrl: './listeners.component.scss'
})
export class ListenersComponent {
  displayedColumns: string[] = ['date', 'listeners', 'amount'];
  listenersData = [
    { date: 'Jan 11 2025', listeners: '243', amount: '$249.99' },
    { date: 'Jan 12 2025', listeners: '2000', amount: '1,999.00' },
    { date: 'Jan 13 2025', listeners: '182', amount: '$249.00' },
    { date: 'Jan 14 2025', listeners: '432', amount: '$399.00' },
    { date: 'Jan 15 2025', listeners: '1389', amount: '1,999.00' },
    { date: 'Jan 16 2025', listeners: '4729', amount: '$1,999.00' },
    { date: 'Jan 17 2025', listeners: '3300', amount: '$1,999.00' }
  ];
}
