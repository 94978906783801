import { Component, OnInit } from '@angular/core';
import { ShowsService } from '../services/shows.service';
import {environment} from "../../environments/environment";
import {Show} from "../interfaces/show.interface";
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-invalidation-logs',
  templateUrl: './invalidation-logs.component.html',
  styleUrl: './invalidation-logs.component.scss'
})
export class InvalidationLogsComponent implements OnInit {
  displayedColumns: string[] = ['show_code', 'total_calls'];
  dataSource = [];
  wesbite_id: number = -1;
	show: any;
  start_date: any;
  end_date: any;
  loading = false;
  processing = false;
  
  constructor(public showsService: ShowsService) {
    this.start_date = new Date();
    this.start_date.setDate(this.start_date.getDate() - 30);
    this.end_date = new Date();
  }

  ngOnInit(): void {
    this.getReport();
  }

  getReport(){
    this.loading = true;
    this.dataSource = [];
    this.showsService.getInvlalidationLogs(this.start_date, this.end_date).subscribe((response: any) => {
      if(response.status){
        this.dataSource = response.shows;
      }
      this.loading = false;
    });
  }
  downloadCsv() {
    if (!this.processing) {
      this.processing = true;
  
      const array = this.dataSource;
  
      const head = ['Show Code', 'User Email', 'Total Calls', 'Last Call Time'];
  
      new AngularCsv(array, 'Invalidation Log '+formatDate(this.start_date,'shortDate', 'en')+' - '+formatDate(this.end_date,'shortDate', 'en'), { headers: head });
  
    }
  }
  
}